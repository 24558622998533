header {
  background-color: #fff;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #efefef;

  @media (max-width: $menuBreakPoint) {
    padding: 20px 0;
  }

  a {
    color: #333;
  }
  .menu-wrapper .uk-grid {
    border-color: #ccc;
  }
  .topbar {
    background-color: #eee;
  }
  .nav-toggle {
    .menu-link i {
      color: #333;
    }
  }

  .slider {
    position: absolute;
    height: 100%;
    width: 100%;
    .slick-list, .slick-track {
      height: 100%;
    }
    .image-wrapper {
      position: relative;
      .image-inner {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }

  .header-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(20,20,20,0.5);
  }


  .logo {
    img {
      margin-top: 30px;
      margin-bottom: 20px;
      max-width: 200px;
      @media (max-width: $menuBreakPoint) {
        margin-top: 0;
      }
    }
  }

  .topbar {
    display: none;
    background-color: #fff;
    height: 40px;

    .social {
      float: left;
      img {
        margin: 10px 1px;
        width: 20px;
      }
    }

    .search {
      float: right;

      input {

        @media (max-width: $breakPoint) {
          width: 100px;
        }
      }

    }
  }

  nav {

    .menu-wrapper {

      /*padding-top: 20px;*/
      .uk-grid {
        /*padding-top: 20px;*/
        border-bottom: 1px solid #fff;
      }
    }

    .header-extras {
      text-align: right;
      padding-right: 10px;
      margin-top: 30px;
      .social {
        display: inline-block;
        margin-right: 20px;
        span {
          margin-left: 5px;
          display: inline-block;
          vertical-align: middle;
          a {
            color: $brandPrimary;
            font-size: 18px;
          }
        }
      }
      .search {
        padding: 3px;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 2px;
        input {
          border: 0;
          display: inline-block;
          padding: 5px;
          margin-right: 10px;
          outline: none!important;
        }
        button {
          background-image: url('/assets/img/search.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-color: transparent;
          width: 20px;
          height: 20px;
          border: 0;
          outline: 0;
          vertical-align: middle;
          cursor: pointer;
        }
      }
    }

    .menu {
      text-align: right;
      margin-top: 20px;
      @media (max-width: $menuBreakPoint) {
        display: none;
      }

      ul {

        padding: 0;
        margin: 0;
        z-index: 9999;
        transition:  all 0.3s ease-in-out;

        li {
          display: inline-block;
          text-align: center;
          position: relative;

          a {
            display: block;
            padding: 10px;
            font-weight: 600;
            font-size: 14px;
            // text-transform: uppercase;
            letter-spacing: 1px;
            font-family: $fontstack;
            &:hover {
              text-decoration: none;
              color: $brandPrimary;
            }
          }

          &:hover > ul {
            visibility: visible;
            opacity: 1;
          }

          &.last ul {
            right: 0;
            left: auto;
          }
        }
      }
    }

    ul.tierTwo {
      position: absolute;
      left: 0;
      background-color: #fff;
      border-radius: 2px;
      padding: 15px 20px;
      visibility: hidden;
      opacity: 0;
      border: 1px solid #eee;
      box-shadow: 0 0 5px rgba(0,0,0,0.2);

      li {
        display: inline-block;
        width: 100%;
        text-align: left;
          a {
            font-size: 13px;
            white-space: nowrap;
            color: #333;
            padding: 0;
          }
      }
    }


  }

  .intro {
    padding-top: 40px;
    @media (max-width: $breakPoint) {
      padding-top: 20px;
    }

    .relative {
      position: relative;
    }

    .slogan {
      width: 100%;
      @media (max-width: $breakPoint) {
        width: 100%;
      }
    }

    .button-view-projects {
      position: absolute;
      right: 0;
      bottom: 0;
      color: #fff;
      white-space: nowrap;
      background-color: rgba(0,0,0,0.3);
      background-image: url('/assets/img/arrow-left.png');
      background-repeat: no-repeat;
      background-position: 200px center;
      margin-top: 30px;
      border: 1px solid #fff;
      display: inline-block;
      padding: 15px 60px 15px 20px;
      font-family: $fontstack;
      font-weight: 400;
      transition: all .2s ease-in-out;
      &:hover {
        text-decoration: none;
        border-color: $brandPrimary;
        color: $brandPrimary;
        padding-right: 70px;
        background-position: 205px center;
      }
      @media (max-width: $breakPoint) {
        margin-top: 20px;
        position:static;
      }
    }

  }





}
.nav-toggle {
  display: none;


  .menu-link {
    color: #fff;
    font-size: 32px;
    text-align: right;
    right: 30px;
    top: 75px;
    z-index: 9999;
    position: absolute;
  }
  @media (max-width: $menuBreakPoint) {
    display: block;
  }

  #sideMenu {
    position: absolute;
    left: 0;
    right: 0;
    top: 130px;
    z-index: 9999;
    background-color: #333;
    display: none;

    &.active {
      display: block;
    }

    &::-webkit-scrollbar {
      display:none;
    }

    .menu-link i {
      font-size: 22px;
      position: relative;
    }

    .tierOne {
      border-bottom: 1px solid #555;
    }

    ul {
      font-size: 0;
      padding: 0;
      margin: 0;
      li {
        position: relative;
        display: inline-block;
        width: 100%;
        border-top: 1px solid #555;
        a {
          display: block;
          color: #fff;
          padding: 7px 10px;
          font-family: $fontstack;
          font-size: 12px;
          text-transform: uppercase;
          &:hover, &focus {
            color: $brandPrimary;
            text-decoration: none;
          }
        }

        ul {
          li {
            background-color: #444;
            a {
              padding-left: 20px;
              text-transform: none;
            }
          }
        }

        i {
          font-size: 16px;
          position: absolute;
          color: #fff;
          padding: 9px 15px;
          right: 0;
          top: 0;
          transition: all .3s ease-in-out;
          & ~ ul {
            display: none;
          }

          &.active {
            transform: rotate(180deg);
            & ~ ul {
              display: block;
            }
          }
        }
      }
    }

  }
}
