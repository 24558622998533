.page-banner {
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(135, 142, 69, 0.64);
  }
}
.entry-page {
  min-height: calc(100vh - 251px);
  padding-top: 30px;

  .last-row {
    margin-bottom: 60px !important;
  }

  .page-title {
    margin: 0px 0 30px 0;
    font-size: 30px;
    font-weight: 400;
    position: relative;
    @media (max-width: $breakPoint) {
      font-size: 32px;
      margin: 0 0 30px 0;
    }

    &:after {
      display: none;
      content: '';
      position: absolute;
      left: 0;
      top: -20px;
      width: 150px;
      height: 10px;
      background-color: $brandPrimary;
      @media (max-width: $breakPoint) {
        top: -10px;
        width: 80px;
        height: 5px;
      }
    }
  }


  .index {
    margin-top: 50px;
  }
  .uk-block {
    border-radius: 2px;
    background-color: $brandPrimary;
    padding: 15px;
    * {
      color: #fff;
    }
    ul li:before {
      border-color: #fff !important;
    }
  }
}
