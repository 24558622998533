.awards {
  margin-top: 0px;
  margin-bottom: 60px;
  .award {
    background-color: #fff;
    border: 1px solid #e2e2e2;
    .inner {
      padding: 15px;
    }
    img {
      width: 100%;
    }
    .year {
      display: block;
      margin-top: 10px;
      font-weight: 800;
      font-size: 22px;
      color: $brandPrimary;
    }
    .title {
      margin: 5px 0;
      font-weight: bold;
      font-size: 28px;
    }
    .description {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}
