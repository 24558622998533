.news-post-list {
  margin-bottom: 40px;
  article {
    margin-bottom: 20px;
    border-radius: 2px;
    border: 1px solid #e2e2e2;
    .inner {
      padding: 15px;
    }
    img {
      width: 100%;
    }
    h2 {
      font-size: 20px;
    }
    p {

    }
    a {
      color: #999;
      display: inline-block;
      border-radius: 2px;

      transition: all .2s ease-out;
      padding: 8px 0px;
      &:hover {
        padding: 8px 16px;
        text-decoration: none;
        color: #fff;
        background-color: $brandPrimary;
      }
    }
  }
  .news-navigation {
    padding: 20px 0;
    text-align: right;
    span {
      float: left;
    }
    a {
      color: #fff;
      display: inline-block;
      border-radius: 2px;
      transition: all .2s ease-out;
      padding: 8px 16px;
      background-color: $brandPrimary;
      &:hover {
        text-decoration: none;
      }
    }
  }
}


.news-post {
  margin-bottom: 30px;
}
