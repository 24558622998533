.services {
  background-color: #fff;
  margin-bottom: 40px;
  margin-top: 10px;

  .services-heading {
    font-size: 28px;
  }

  .service-block-wrapper {
    position: relative;
  }

  .service-block {
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    text-decoration: none!important;
    color: #555;
    &:hover {
      h2 {
        color: #fff;
        left: 10px;
      }
      p {
        opacity: 0;
      }
      .service-block-inner {
        background-color: rgba(0,0,0,0.6);
        &:after {
          padding: 10px;
          max-height: 100px;
        }
      }
    }
  }

  .service-block-inner {
    background-color: #fff;
    transition: all .2s ease-out;
    position: relative;
    overflow: hidden;
    &:after {
      font-family: FontAwesome, $fontstack;
      padding: 0px 10px;
      color: #fff;
      max-height: 4px;
      vertical-align: middle;
      content: "Find out more \f178";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $brandPrimary;
      transition: all .2s ease-out;
    }
    h2 {
      position: relative;
      left: 0;
      padding: 10px 10px 0 0;
      display:block;
      margin: 0;
      min-height: 45px;
      text-transform: capitalize;;
      font-size: 22px;
      font-family: $fontstack;
      letter-spacing: .5px;
      transition: all .2s ease-out;
    }
    p {
      width: 100%;
      max-width: 280px;
      height: 100px;
      transition: all .2s ease-out;
    }
  }

}


.intro {
  margin-top: 50px;

  .about-block {
    position: relative;
    @media (max-width: 960px) {
      padding-bottom: 0;
    }


      h2 {
        font-family: $fontstack;
        font-weight: bold;
        font-size: 36px;
        position: relative;
        display: inline-block;
      }
      p {
        font-size: 15px;
        line-height: 1.4;
        color: #666;
      }
      a {
        color: $brandPrimary;
      }

    .image {
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      background-color: #333;
    }
  }

  .equity {
    margin: 50px 0 30px 0;
    position: relative;
    span {
      color: $brandPrimary;
      font-family: $fontstack;
      position: absolute;
    }
    .number {
      position: static;
      font-size: 100px;
    }
    .percent {
      left: 125px;
      font-size: 40px;
      bottom: 50px;
    }
    .text {
      font-size: 18px;
      left: 125px;
      bottom: 3px;
    }
  }
}


.latest-news {
  margin-bottom: 30px;
  .heading {
    font-size: 28px;
  }
  article {
    position: relative;
    border-radius: 2px;
    overflow: hidden;
    &.small {
      padding-bottom: 200px;
    }
    &.wide {
      padding-bottom: 200px;
    }
    &.tall {
      padding-bottom: 200px;
      @media (min-width: 961px) {
        padding-bottom: 410px;
      }
    }
    &.red { background-color: $bvi_darkRed;}
    &.green { background-color: $bvi_lime;}
    &.blue { background-color: $bvi_navy;}
    &.grey { background-color: $bvi_grey;}


    &:hover {
      .image {
        opacity: 0;
      }
      a {
        padding: 8px 16px;
        background-color: rgba(255,255,255,1);
        text-decoration: none;
        color: #333;
      }
    }
    .image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-position: center;
      background-size: cover;
      opacity: .3;
      transition: all .2s ease-out;
    }
    .inner {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px;
      position: absolute;
      h4 {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
      p {
        // height: 80px;
        color: #fff;
      }
      a {
        color: #fff;
        display: inline-block;
        border-radius: 2px;
        background-color: rgba(255,255,255,.0);
        transition: all .2s ease-out;
        padding: 8px 16px 8px 0;
      }
    }
  }
}
