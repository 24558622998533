.history {
  padding-bottom: 60px;

  .heading {
    margin-bottom: 30px;
  }

  .timeline {
    position: relative;

    .uk-width-1-1 {
      position: relative;
      transition: all .3s ease-out;
      box-shadow: 0 0 0 rgba(0,0,0,0);
      background-color: #fff;
      &:first-child {
        .year {
          font-size: 26px;
          font-weight: bold;
          color: #333;
          span {
            font-size: 20px;
          }
        }
        .info {
          font-size: 16px;
          color: #666;
        }
      }
    }

    .timeline-dot-line {
      border-left: 2px solid $brandPrimary;
      left: 40px;
      top: 0;
      bottom: 0;
      position: absolute;
      display: inline-block;

      &:before {
          content: '';
          position: absolute;
          background-color: $brandPrimary;
          width: 10px;
          height: 10px;
          left: -9px;
          top: 13px;
          margin-top: -14px;
          border-radius: 20px;
          border:3px solid #fff;
      }
    }

    .year {
      margin: -5px 0 5px 0;
      color: #666;
      font-size: 16px;
      span {
        color: $brandPrimary;
        font-size: 14px;
        letter-spacing: .5px;
        font-weight: bold;
        display: inline-block;
        margin-right: 10px;
      }
    }

    .info {
      font-family: $fontstack;
      color: #999;
      line-height: 1.4;
    }

    .moment {
      padding: 0px 10px 20px 50px ;
      @media (max-width: $breakPoint) {
        padding-left: 30px;
      }
      vertical-align: middle;
      line-height: 1;
    }
  }



}

.about {
  .about-banner {
    margin-bottom: 30px;
  }
}
