/* Vendors */
@import 'uikit/dist/css/uikit.min.css';
@import 'uikit/dist/css/uikit.almost-flat.min.css';
@import 'uikit/dist/css/components/slidenav.min.css';
@import 'slick-carousel/slick/slick.css';

$fontstack: 'Source Sans Pro', 'Helvetica', 'Arial', sans-serif;
$fontCopy: 'Open Sans','Helvetica', 'Arial', sans-serif;

$breakPoint: 769px;
$menuBreakPoint: 960px;

/* BVI Colours */

/* Primary */
$bvi_darkGreen: #53682B;
$bvi_lightGreen: #7AB800;
$bvi_grey: #949D9E;

/* Secondary Colours */
$bvi_brown: #6E7645;
$bvi_purple: #8F8DCB;
$bvi_darkRed: #AA272F;
$bvi_coolGrey: #616365;
$bvi_lime: #A2AD00;
$bvi_navy: #331C54;
$bvi_maroon: #983222;

$brandPrimary: #A2AD00;


body {
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $fontstack;
  line-height: 1;
  letter-spacing: 1px;
}
h1 {
  font-size: 38px;
  font-weight: 900;
}
h2 {
  font-size: 26px;
  // font-weight: 700;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 20px;
  line-height: 26px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
  a {
    background-color: $brandPrimary;
    padding: 10px 15px;
    display: inline-block;
    color: #fff;
    text-decoration: none!important;
    transition: all .2s ease-out;
    &:hover {
      color: #fff;
      background-color: $bvi_darkGreen;
    }
  }
}
p {
  line-height: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #777;
  em {
    color: inherit;
  }
}
.green-lead {
  font-size: 18px;
  font-weight: 600;
  color: $brandPrimary;
}
ul, ol {
  li {
    font-weight: 400;
    font-size: 15px;
    color: #777;
    ul {
      padding-top: 10px;
      li {

      }
    }
  }
}
hr {
  margin-top: 40px!important;
  margin-bottom: 50px!important;
  &.invisible {
    opacity: 0;
  }
}

* {
  font-family: $fontCopy;
}
.section-heading {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 24px;
}

@import "_partials/header/header.scss";
@import "_partials/footer/footer.scss";
@import "_partials/featured-projects/featured-projects.scss";
@import "_partials/content-blocks/content-blocks.scss";


@import "_layouts/home/home.scss";
@import "_layouts/page/page.scss";
@import "_layouts/projects/projects.scss";
@import "_layouts/about/about.scss";
@import "_layouts/contact/contact.scss";
@import "_layouts/search/search.scss";
@import "_layouts/awards/awards.scss";
@import "_layouts/careers/careers.scss";
@import "_layouts/news/news.scss";
@import "_layouts/service/service.scss";


.uk-vertical-align-top {
  vertical-align: top;
  display: inline-block;
  max-width: 100%;
  font-size: 1rem;
}
