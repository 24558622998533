.projects {

  .project-thumb {
    display: inline-block;
    transition: all .1s ease-in-out;
    border: 1px solid #ccc;
    &-inner {
      padding: 10px;
      h5 {
        @media (max-width: $breakPoint) {
          font-size: 14px;
          margin-bottom: 5px;
        }
      }
    }
    small {
      color: #999;
      display: inline-block;
      line-height: 16px;
    }
    &:hover {
      text-decoration: none;
      transform: scale(1.03);
    }
  }

  .uk-article {
    @media (max-width: $breakPoint) {
      padding-top: 60px;
      padding-bottom: 30px;
    }
  }

  .uk-modal-close {
    background-color: #fff;
    color: $brandPrimary;
    padding: 25px;
    right: 0 !important;
    top: 0 !important;
    opacity: 1;
    font-size: 30px;
  }

  .filter-heading {
    display: block;
    color: $brandPrimary;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  #projectsIndustries {
    padding: 0;
    display: block;
    margin-left: 0px;
    margin-bottom: 30px;
    margin-top: 0;
    font-size: 0;

    li {
      margin: 0;
      padding: 0;
      display: block;
      float: none;
      @media (max-width: 960px) {
        width: 50%;
        display: inline-block;
      }
    }
    a {
      display: block;
      font-size: 12px;
      letter-spacing: 1px;
      padding: 5px;
      color: #333;
      transition: all .3s ease-out;
    }


    .active a {
      background-color: $brandPrimary;
      color: #fff;
    }
    a:hover, a:focus {
      text-decoration: none;
      background-color: $bvi_grey;
      color: #fff;
    }
  }

  .project-filter {
    text-align: left;

    span {
      color: $brandPrimary;
      display: block;
      position: relative;
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px;
      &:hover {
        & ul {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    ul {
      z-index: 1;
      display: block;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: 0;
      background-color: #fff;
      top:100%;
      margin: 0;
      padding: 0;
      box-shadow: 0 0 10px rgba(0,0,0,.2);
      li {
        margin: 0;
        padding: 0;
        float: none;
        display: block;
        &.uk-active a {
          color: #fff;
          background-color: $brandPrimary;
        }
        a {
          display: block;
          padding: 5px 10px;
          font-size: 12px;
          &:hover {
            text-decoration: none;
            background-color: $bvi_grey;
            color: #fff;
          }
        }
      }
    }
  }
}
