.page-title-search {
  input {
    width: 100%;
    padding-bottom: 20px;
    color: #333;
    font-family: $fontstack;
    border:0;
    outline: 0;
    font-size: 48px;
    border-bottom: 1px solid rgba(0,0,0,0);
    transition: all .3s ease-in-out;
    @media (max-width: $breakPoint) {
      font-size: 32px;
    }
    &:focus {
      border-bottom: 1px solid rgba(0,0,0,0.5);
    }
  }
}
