.featured-projects {
  position: relative;
  .project {
    position: relative;
    overflow: hidden;
    height: 400px;
    @media (min-width: $breakPoint) {
      height: 400px;
    }
    .project-image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-position: center;
      background-size: cover;
    }
    .project-info {
      position: relative;
      height: 400px;
      @media (min-width: $breakPoint) {
        height: 400px;
      }
      .project-info-inner {
        position: absolute;
        left:0;
        right:0;
        bottom: 0;
        border-radius: 2px 2px 0 0;
        background-color: #fff;
        padding: 15px;
        transition: all .2s ease-out;
        transition-delay: .5s;
        @media (min-width: $breakPoint) {
          top: 100px;
        }
        .project-title {
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
        .project-excerpt {
          color: #999;

        }
        .project-link {
          color: $brandPrimary;
        }
      }
    }
  }

  .slider-nav {
    width: 100%;
    bottom: 30px;
    @media (min-width: $breakPoint) {
      position: absolute;
    }
    .inner {
        position: relative;
        white-space: nowrap;
        font-size: 0;
        @media (min-width: $breakPoint) {
          left: 100%;
          transform: translateX(-60px);
        }
    }
    button, a {
      border-radius: 2px;
      font-size: 12px;
      text-transform: uppercase;
      border: none;
      background-color: #fff;
      font-family: $fontstack;
      height: 60px;
      line-height: 60px;
      min-width: 60px;
      vertical-align: middle;
      display: inline-block;
      outline: none!important;
      color: $brandPrimary;
      margin-right: 10px;
      cursor: pointer;
      transition: all .2s ease-out;
      i {
        vertical-align: text-top;
        line-height: 12px;
        font-size: 16px;
      }
      &:hover {
        background-color: $brandPrimary;
        color: #fff;
        text-decoration: none;
      }
    }
    button.left {
      border-radius: 2px 0 0 2px;
      background-color: $brandPrimary;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: $brandPrimary;
      }
    }
    a {
      padding: 0px 20px;
      letter-spacing: 1px;
    }
  }
}
