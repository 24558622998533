footer {
  margin-top: 30px;
  padding: 30px 0;
  background-color: #333;
  color: #fff;
  .copy {
      font-size: 11px;
  }
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  &.not-home {
    margin-top: 60px;
  }
}
