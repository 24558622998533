.contact {
  margin-bottom: 60px;

  .uk-button {
    font-family: $fontstack;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $brandPrimary;
    color: #fff;
    &:hover {
      background-color: $brandLight;
    }
  }

  .contact-list {
    padding: 0;
    margin: 0;
    @media (max-width: $breakPoint) {
      margin-top: 20px;
    }
    .list-header {
      font-family: $fontstack;
      display: block;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-size: 16px;
      border-bottom: 1px solid $brandPrimary;
    }
    li {
      display: inline-block;
      width: 100%;
      a {
        display: inline-block;
        font-family: $fontstack;
        color: #333;
        text-transform: uppercase;
        &:hover {
          text-decoration: none;
          color: $brandPrimary;
        }
      }
    }
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .info {
    margin-bottom: 20px;
    span {
      display: inline-block;
    }
  }

  .icon {
    width: 20px;
  }



  .map {
    border: 10px solid #fff;
  }
}
.contact-offices {
  background-color: #81bc41;
  padding: 20px 0;

  .office-name {
    color: #374a00;
    font-weight: bold;
    &:hover {
      color: #fff;
    }
  }
}
