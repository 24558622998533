.side-menu {
  list-style: none;
  margin: 0;
  margin-bottom: 20px!important;
  padding: 10px;
  background-color: $bvi_darkRed;
  &.alt {
    background-color: $bvi_navy;
  }
  span {
    display: block;
    font-weight: bold;
    color: #fff;
    font-size: 16px;
    padding-bottom: 10px;
    margin-left: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #fff;
  }
  li {
    a {
      display: block;
      padding: 5px;
      color: #fff;
      &:hover, &:focus {
        background-color: $bvi_grey;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
