.content-blocks {
  .columns {
    //margin-top: 20px;
  }

  ul {
    padding-left: 0;

    li {
      list-style: none;
      padding: 5px 5px 5px 15px;
      position: relative;
      &:before {
        font-family: FontAwesome;
        position: absolute;
        left: 0;
        text-indent: 0;
        content: '\f111';
        color: $brandPrimary;
        font-size: 7px;
        line-height: 22px;
      }
      ul li {
        &:before {
          color: #777;
          font-size: 5px;
        }
      }
    }
  }

  .fullbleed-image {
    margin: 60px 0;
    position: relative;
    // padding-bottom: 28%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.last {
      margin-bottom: 0px !important;
    }
    &.medium {
      height: 300px;
    }
    &.big {
      height: 600px;
    }
    &.viewport {
      height: 100vh;
    }

    .overlay {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.75;
    }

    .inner-wrapper {
      position: relative;;
      top: 0;
      z-index: 2;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      * {
        color: #fff;
      }
      .height-fill {
        height: 100%;
      }
      .inner-text {
        margin-top: 30px;
        margin-bottom: 30px;
      }

    }
  }
}
